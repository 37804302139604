.btn1{
    margin-top: 15px;
    box-shadow: none !important;
    border: none !important;
}
.btn2{
    margin-top: 15px;
    background-color: rgb(83, 83, 83);
    box-shadow: none !important;
    border: none !important;
}
.side-topbtn{
    margin-top: 15px;
    box-shadow: none !important;
    border: none !important;
}
.sidemenu-div{
    border: 1px solid #d3d3d3;
    padding: 10px;
    margin-top: 15px;
}
.side-rightbtn{
    width: 100px;
    margin-right: 15px;
    box-shadow: none !important;
    border: none !important;
    margin-top: 15px;
}
.side-rightdownbtn{
    width: 150px;
    height: 100px;
    margin-right: 15px;
    box-shadow: none !important;
    border: none !important;
    margin-top: 15px;
    background-color: #d3d3d3;
    color: #000
}
.poscat-btn{
    width: 100px;
    box-shadow: none !important;
    border: none !important;
    margin-top: 15px;
    background-color: rgb(83, 83, 83);
    color: #fff
}
.posdown-btn{
    width: 100px;
    margin-right: 15px;
    box-shadow: none !important;
    margin-top: 15px;
    background-color: #fff;
    color: #0d6efd
}
.posdown-btn1{
    width: 200px;
    margin-right: 15px;
    box-shadow: none !important;
    margin-top: 15px;
    background-color: #fff;
    color: #0d6efd
}

/* pos bill */
.billReciept{
    border: 1px solid #d2d2d2;
    padding: 10px;
    font-size: 12px!important;
}