
@font-face {
  font-family: "GoldmanBold";
  font-display: swap;
  src: local("GoldmanBold"),
   url("./fonts/verdana-bold.ttf") format("opentype");
  font-weight: bold;
  }
  
  .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 41px !important;
}



.main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 260px;
  height: 100%;
  background-color: #fff !important;
  border-bottom: 1px solid #dee4ec;
  box-shadow: 5px 7px 26px -5px #cdd4e7;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  left: 0;
}


.toggle .main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 260px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  left: -100%;
}

.main-content {
  position: relative;
  z-index: 10;
  margin-left: 280px;
  margin-right: 20px;
  padding-top: 5px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.footer-content {
  position: relative;
  z-index: 10;
  margin-left: 260px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  margin-top: auto;
}

.toggle .footer-content {
  position: relative;
  z-index: 10;
  margin-left: 0px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  margin-top: auto;
}

.toggle .main-content {
  position: relative;
  z-index: 10;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 20px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.toggle .menu-icon {
  margin-left: 15px!important;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
  color: #000;
}

.menu-icon {
  margin-left: 280px!important;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}


@media (max-width:600px) {
    .main-menu {
          width: 250px;
          height: 100vh;
          display: block!important;
          justify-content: center;
          position: fixed;
          top: 0;
          left: -100%;
          transition: 350ms;
          z-index: 1000;
          height: 100%;
          cursor: pointer;
          margin-top: 58px!important;
    }
    .toggle .main-menu { 
          width: 250px;
          height: 100vh;
          display: block;
          justify-content: center;
          position: fixed;
          top: 0;
          left: -0%;
          transition: 350ms;
          z-index: 1000;
          height: 100%;
          cursor: pointer;
    }
    .main-content {
      margin-left: 15px!important;
      margin-right: 15px;
    }
    .footer-content {
      margin-left: 0px!important;
    }
    .menu-icon {
      margin-left: 15px!important;
    }

    .header-logo {
      display: none!important;
    } 
}

.header-logo {
  width: 100%;
  padding: 20px;
  font-size: 25px;
  overflow: hidden;
  color: #000;
  font-weight: bold;
  margin-bottom: unset;
  text-align: center;
}

.hoveritem:hover svg{
  color: #000 !important;
}

.menu-container {
  height: 100%;
  overflow: auto;
  padding-bottom: 280px;
}

.activeItem {
  background: #efefef;
	border-left: 6px solid #38B0DE;
	border-right: 6px solid #38B0DE;
	cursor: pointer;
	color: #38B0DE!important;
}

.activeItem svg{
  color: #38B0DE !important;
}

.subactiveItem {
  background: #efefef;
  cursor: pointer;
	color: #38B0DE!important;
}

.subactiveItem svg{
  color: #38B0DE !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  background-color: #ffffff;
  top: -3px;
  padding-left: 5px;
  padding-right: 5px;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root{
  background-color: #ffffff;
  top: -3px!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: unset;
}

.MuiTablePagination-displayedRows {
  margin-bottom: unset;
}

.pac-container{
  z-index: 2000;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em!important;
}


.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: -9px!important;
}

.border-color{
  border: 1px gray solid
}

.card {
  transition: all 0.5s;
  border: 0;
  position: relative;
  width: 100%;
  box-shadow: none;
  background: transparent;
}

.w_icon.indigo {
  background: rgba(153,136,255,0.3);
  color: #98f;
}

.w_icon {
  -webkit-border-radius: 54px;
  -moz-border-radius: 54px;
  -ms-border-radius: 54px;
  border-radius: 54px;
  position: relative;
  margin: auto;
  height: 54px;
  width: 54px;
  line-height: 54px;
}

.w_data_1 {
  text-align: center;
}

.card-body{
  font-weight: 400;
    border-radius: 0.35rem;
    background: #fff;
    font-size: 14px;
    color: #222;
    padding: 20px;
}

.table-footer {
  background:  linear-gradient(12deg, rgba(80,0,137,1) 40%, rgba(212,109,255,1) 100%);
  padding: 10px;
}

.header-table{
  background:  linear-gradient(12deg, rgba(80,0,137,1) 40%, rgba(212,109,255,1) 100%);
  padding-top: 20px;
}


.spcl .css-1o2jng6-MuiOutlinedInput-notchedOutline{
  background: #fff;
  border: unset;
  color: #495057;
}

.clr .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  color: #fff;
}

.tableheight .table-responsive {
    height: 200px;
    overflow-y: scroll;
}

.dash-numbers{
  height: 172px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 10%);
}

.dash-round {
  padding: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #E6EFFF;
  margin-left: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff)) !important;
  background: linear-gradient(to right, #da8cff, #9a55ff) !important;
}

.erp-icon-rectangle {
  width: 61px;
  height: 61px;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #ffffff;
  padding-top: 15px;
}

.bg-gradient-warning, .datepicker-custom .datepicker.datepicker-inline .datepicker-days .table-condensed tbody tr td.day.active:before {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
  background: linear-gradient(to right, #f6e384, #ffd500) !important;
}

.bg-gradient-success, .datepicker-custom .datepicker.datepicker-inline .datepicker-days .table-condensed tbody tr td.day.today:before {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
  background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}

.bg-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096)) !important;
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}

.userpic{
  width: 35%;
    max-width: 75px;
    margin: 0 auto;
}

.userpic img{
  background: #fff;
  z-index: 1000;
  position: inherit;
  padding: 2px;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
  border-radius: 15%;
}

.paragraphcommon {
margin-bottom: unset;
}

.list-inline {
  display: flex;
}

.list-inline li {
  margin-left: 10px;
}

.ss .modal-dialog {
  max-width: unset!important;
}

.cashbookbody{
  border-collapse: 'collapse';
  border: 1px solid #000!important;
}

.cashbook.css-xn82ks-MuiTable-root{
  border-collapse: collapse;
}
.printdate{
  display: none !important;
}
.linebreak{
  word-break: break-word !important;
  white-space: pre-wrap !important;
}
@media print {
  
  .billnohide{
    display: block !important;
  }
  .tablecellPrint {
    page-break-inside: avoid!important;
    border: 1px solid #000!important;
    -webkit-print-color-adjust: exact;
    border-left: 1px solid #000;
    max-height: 10px!important;
    font-size: 18px!important;
    padding: 2px!important;
  }
.printdate{
  display: block !important;
}
  .noprint{
    display: none !important;
  }

  /* .daybook .spacereduce {
    line-height: 0.2!important;
  } */

  .daybook td {
    line-height: 0.9!important;
  }
  
  body * {
    visibility: hidden;
    color:black!important;
  }

  .godownprnt {
    display: none !important;
  }
  .printDnone{
    display: none!important;
  }
  .unboarder {
    border-width: 0px 1px 0px 1px !important;
    max-height: 10px!important;
      font-size: 18px!important;
      padding: 4px!important;
  }
  .unboarder1{
    font-size: 18px!important;
  }
  .tablemargin {
    margin-left: 30px;
    width: '100%'
  }
  .section-to-print * {
    visibility: visible!important;
  }
  .section-to-print {
    margin: 0px 18px 0px 30px;
    /* position: absolute; */
    left: 0;
    top: 0;
    page-break-after:auto ;
    -webkit-print-color-adjust: exact;
    border-left: 1px solid #000!important;
    /* margin: 0px 20px 0px 0px; */
    font-family: "GoldmanBold" !important;
  }
  

  .posprint * {
    visibility: visible!important;
    /* width: 100%!important; */
    
  }

  .salesbilllist{
    width: 270px!important;
  }

  .posprinttable tr td{
    font-size: 10px;
    padding: 3px;
    font-weight: bold!important;
  }

  .posprinttable tr th{
    font-size: 11px;
    font-weight: bold;
    padding: 3px;
  }

  .posprinttable{
    font-weight: bold;
  }

  .posprint{
    position: absolute;
    left: 0;
    top: 0;
    page-break-after:auto ;
    -webkit-print-color-adjust: exact;
    border-left: 1px solid #000!important;
  }

  .captiontable {
    caption-side: top!important;
    text-align: center!important;
    font-size: 20px;
  }
  

  /* #section-to-print, #section-to-print * {
    visibility: visible!important;
  }
  #section-to-print {
    position: absolute!important;
    left: 0!important;
    top: 0!important;
    page-break-after: always!important; 
    page-break-inside: avoid!important;
    right: 0!important;
    break-inside: avoid!important;
    -webkit-print-color-adjust: exact;
    border-left: 1px solid #000!important;
    width: 280px!important
  } */
  .testprint{
    display: block!important;
    font-family: GoldmanBold!important;
  }

  .print_btn{
    display:none;
    }
    /* .tablerowPrint {
      page-break-inside: avoid!important;
      border: 1px solid #000!important;
      -webkit-print-color-adjust: exact;
      height: 50px!important;
      
    }  */
    .bodytable{
      border-left: 1px solid #000!important;
      border: 1px solid #000!important;
    }
    

    
  
  .printdisplay{
    display: block!important;
  }
  /* .section-to-print tr td  {
    border: 1px solid #000 !important;
    border-width: 1px 1px 1px 1px !important;
}  */

@page {
  size: auto;
  margin: 2mm 0mm 2mm 0mm;
}
}

.unboarder {
  border-width: 0px 1px 0px 1px !important;
  max-height: 10px!important;
      font-size: 12px;
      padding: 4px!important;
}

.tablerowPrint {
  page-break-inside: avoid!important;
  border: 1px solid #000!important;
  -webkit-print-color-adjust: exact;
  height: 10px!important;
} 


.sizecolumn td {
  font-size: 12px!important;
}
.sizecolumn th {
  font-size: 11px!important;
  font-weight: bold;
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.captiontable {
  caption-side: top!important;
  text-align: center!important;
}

.printdisplay {
  display: none!important
}



/* test */

.maindiv {
  display: flex;
  justify-content: center;
}

.Boxdiv {
  background-color: red;
  width: 300px;
  height: 150px;
}

.leftdiv{
  width: 50%;
  flex-direction: column;
}

.topleftdiv {
  height: 60%;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
}
.bottomleftdiv {
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
}
.rightdiv{
  width: 50%;
}

.tablebranch .table-responsive{
  height: 200px;
  overflow: auto;
}

.widthmodal .modal-lg, .modal-xl {
  max-width: 1040px!important;
}

.testprint{
  display: none;
}

.countersaledropdown .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 2px!important
}

.countersaletable .rowstyle{
  padding: 15px!important;
  font-size: 14px!important
}

.borderall{
    border-bottom: 1px solid #dee2e6;
}

.tablecellPrint {
  page-break-inside: avoid!important;
  border: 1px solid #000!important;
  -webkit-print-color-adjust: exact;
  height: 10px!important;
  font-size: 13px;
  padding: 2px!important;
  
}




.autocomplete {
  position: absolute;
  z-index: 999;
}

.people {
  width: 100%;
  max-width: 20rem;
  font-size: 15px;
  list-style: none;
  background-color: white;
  padding: 0;
  border: solid 1px gray;
  margin: 0 auto;
  color: unset;

}

.people li a {
  display: block;
  padding: 2px;
  outline: none;
  color: unset;
  text-decoration: unset;
}

/* .people li  {
  border: 1px solid;
} */

.people li a:focus {
  background-color: lightblue;
}

.scrolling-div{
  height: 290px;
  overflow: scroll;
}

.scrolling-div li a {
  font-size: 15px!important;
}

/* @page { counter-increment: page;  margin: 10mm 10mm 10mm 10mm; color:#000 !important; } 
@page { @bottom-right { content: counter(page) " of " counter(pages); } } */

/* @page {
  counter-increment: page;
  counter-reset: page 1;
  @bottom-right {
      content: "Page " counter(page) " of " counter(pages);
  }
} */

.billnohide{
  display: none ;
}