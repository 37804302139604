.biillls-div{
    border: 1px solid #d2d2d2;
    width: 80%;
    padding: 25px;
    margin-top: 30px;
}

@media (max-width: 500px){
    .biillls-div{
        width: 100% !important;
    }
}
@media (min-width: 501px) and (max-width: 991px){
    .biillls-div{
        width: 70% !important;
    }
}