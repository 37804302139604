
.log-headp{
    text-align: center;
    color: #0d6efd;
    font-size: 35px;
    font-weight: bold;
}
.log-para{
    color: #7d7d7d;
    font-size: 13px;
    text-align: center;
}

.inputtext{

    margin-top: 30px;
    padding-left: 60px;
    padding-right: 65px;
}
.login{
    margin: 0;
    overflow: hidden;
}

.textbox1{
    border-radius: 20px !important;
    height: 50px;
    margin-top: 50px;
    box-shadow: none !important;
    padding-left: 20px !important;
}
.textbox2{
    border-radius: 20px !important; 
    height: 50px; 
    margin-top: 30px;
    box-shadow: none !important;
    padding-left: 20px !important;
}

.forgotpassword {
    float: right;
    display: flex;
    margin-top: 8px;
  }
  .forgot-text {
    color: #808080;
    font-size: 13px;
    
  }
  .css-ahj2mt-MuiTypography-root {
      font-size: 12px !important;
  }
  .loginbtn{
    width: 100%;
    height: 40px;
    border-radius: 20px !important;
    box-shadow: none !important;
    margin-top: 50px;
  }

  .login-bottom1{
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
  }
  .login-bottom2{
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
  }

  .login-img{
      width: 100%;
      max-height: 100vh;
  }
  .login-img1{
    width: 80px;
    height: 80px;
}

  @media (max-width: 767px){
    .login-img{
        display: none;
    }
    .login-img1{
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px){
    .inputtext {
        padding-left: 0px !important;
        padding-right: 18px !important;
    }
  }

  .login-name{
    margin-top: 55px;
    border: 1px solid #d3d3d3;
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 30px;
  }