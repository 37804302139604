.App {
    text-align: center;
  }

  .onborad-box {
    display: flex;
    justify-content: center;
}

.onboardbox-inside {
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
    width: 30%;
    margin-top: 30px;
    transition: all 500ms ease;
}
.onboardbox-inside:hover{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all 500ms ease;
    -webkit-box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
    box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
}

.onboardbox-head{
    color: #1d84df;
    font-size: 60px;
}
.onboardbox-para{
    color: #1d84df;
    font-size: 16px;
}
.second-box{
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
    margin-top: 40px;
    transition: all 500ms ease;
    cursor: pointer;
}
.second-box:hover{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all 500ms ease;
    -webkit-box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
    box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
}

.second-box2{
    background: #808080;
    opacity: 0.4;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
    margin-top: 40px;
    min-height: 150px;
    transition: all 500ms ease;
    
}
.second-box2:hover{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all 500ms ease;
    -webkit-box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
    box-shadow: 0 5px 13px rgb(60 72 88 / 20%) !important;
}

.comingsoon{
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
}
.onboard-companies{
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    text-align: start;
}
.box-para1{
    text-align: center;
    color: #1d84df;
    font-size: 24px;
}
.box-para3{
    text-align: start;
    font-size: 18px;
}
.onboard-icons{
    width: 50;
   
}

@media (max-width:991px) {
    .onboardbox-inside {
        width: 60%;
    }
}