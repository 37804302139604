.counter-secondrow{
    border: 2px solid #929292;
    padding: 15px;
    width: 100%;
}
.counter-firstrow{
    border: 2px solid #929292;
    padding: 10px;
    background-color: #fff;
}
.counter-firstrow2{
    border: 2px solid #929292;
    border-top: none;
}
.counter-firstrow2-div{
    background-color: #03a5fc;
    padding-top: 15px;
    padding-bottom: 1px;
    cursor: pointer;
}
.counter-firstdivpara{
    color: #000; 
    font-size: 13px;
    font-weight: bold;
     margin-bottom: unset
}