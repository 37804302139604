.printborderm{
    border: 1px solid #dfe2e6;
    padding: 15px;
}

.tax-border{
    border-bottom-color: #fff !important;
    
}
.tax-bord{
    border-color: #4d4d4d !important;
}

@media print {
  
    .printborderm{
        height: 98vh !important;
    }
}