.printborder{
    border: 1px solid #dfe2e6;
    padding: 15px;
}

@media print {
  
    .printborder{
        height: 98vh !important;
    }
}