.selected {
    padding-top: 15px;
    padding-bottom: 18px;
    padding-left: 15px!important;
    padding-right: 15px!important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
}

@media (max-width: 1055px) {
    .selected {
        margin-left: -15px !important;
    }
}